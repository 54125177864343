export const firebaseConfig = {
    apiKey: "AIzaSyDIty7C5HErPhER7lKHvZO8iYNHZ8GVVXU",

  authDomain: "auth-test-763e7.firebaseapp.com",

  projectId: "auth-test-763e7",

  storageBucket: "auth-test-763e7.appspot.com",

  messagingSenderId: "537780381754",

  appId: "1:537780381754:web:b36f302c8b87ce491b2d03"

};