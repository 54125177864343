<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div
      v-if="isLoading"
      class="h-screen w-screen flex flex-wrap justify-center content-center"
    >
      <LoadingVue></LoadingVue>
    </div>
    <!-- a pw ka hr ko open yin dr ko v-else -->
    <div
      v-else
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
    >
      <div
        class="max-w-2xl bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
      >
        <a href="#">
          <img class="rounded-t-lg" src="../assets/1299888.png" alt="" />
        </a>
        <div class="p-5">
          <a href="#">
            <h5
              class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
            >
              You are logged in!!!
            </h5>
          </a>
          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
            Hello ninja. Welcome to the hidden leaf!!
            <br />
            This is the sample login flow implemented to use as a drop-in
            solution. This solution used firebase authentication web flow. Users
            will be logged in in until they log out by themselves. The sessions
            are maintained by firebase. Have a great day ninja!!
          </p>
          <div class="flex">
            <a
              href="#"
              @click.prevent="logout"
              class="items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import firebase from "firebase/compat/app";
  import { getAuth } from "firebase/auth";
  import router from "@/router";

  import LoadingVue from "@/components/Loading.vue";

  export default {
    name: "Home",
    components: {
      LoadingVue,
    },
    data() {
      return {
        isLoading: true,
      };
    },
    methods: {
      logout() {
        const auth = getAuth();
        auth
          .signOut()
          .then(() => {
            // Logout successful
            console.log("User logged out");
            router.push({ path: "/signin" });
            // You can perform further actions after successful logout, such as redirecting the user to a different page
          })
          .catch((error) => {
            // An error occurred during logout
            console.error("Logout error:", error);
          });
      },
    },
    mounted() {
      // -- this is for auth guard if we want to do it in component.
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in
          console.log("User is authenticated");
          this.isLoading = false;
          // You can perform further actions for authenticated users
        } else {
          // User is not signed in
          console.log("User is not authenticated");
          router.push({ path: "/signin" });
        }
      });
    },
  };
</script>

<style></style>
