import { createApp } from "vue";
import App from "./App.vue";
import "./assets/css/main.css";

import router from "./router";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "./firebaseConfig";

firebase.initializeApp(firebaseConfig);

createApp(App).use(router).mount("#app");
