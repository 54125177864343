<template>
  <router-view />
</template>

<style>
  #app {
  }

  nav {
    padding-top: 30px;
  }

  nav a {
    font-weight: bold;
    color: white;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }
</style>
